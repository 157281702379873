import { Action, ActionReducer, ActionReducerMap, MetaReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';

import { environment } from '../../environments/environment';
import { signOut } from '../actions/auth.actions';
import { commonIdSignOut } from '../actions/common-id/common-id-auth.actions';

import * as fromAuth from './auth.reducer';
import * as fromBookmark from './bookmark.reducer';
import * as fromBulkMail from './bulk-mail.reducer';
import * as fromCommonIdAnsweredProblem from './common-id/common-id-answered-problem.reducer';
import * as fromCommonIdAuth from './common-id/common-id-auth.reducer';
import * as fromCommonIdBookmarkAndAnsweredPaper from './common-id/common-id-bookmark-and-answered-paper.reducer';
import * as fromCommonIdFindUniversity from './common-id/common-id-find-university.reducer';
import * as fromCommonIdGetCustomToken from './common-id/common-id-get-custom-token.reducer';
import * as fromCommonIdGetStripeCheckoutUrl from './common-id/common-id-get-stripe-checkout-url.reducer';
import * as fromCommonIdInformation from './common-id/common-id-information.reducer';
import * as fromCommonIdPaperBookmark from './common-id/common-id-paper-bookmark.reducer';
import * as fromCommonIdPlaylistBookmark from './common-id/common-id-playlist-bookmark.reducer';
import * as fromCommonIdPlaylist from './common-id/common-id-playlist.reducer';
import * as fromCommonIdPurchases from './common-id/common-id-purchases.reducer';
import * as fromCommonIdSearch from './common-id/common-id-search.reducer';
import * as fromCommonIdUniversityBookmark from './common-id/common-id-university-bookmark.reducer';
import * as fromCommonIdUser from './common-id/common-id-user.reducer';
import * as fromCommonIdVisitedPaper from './common-id/common-id-visited-paper.reducer';
import * as fromCore from './core.reducer';
import * as fromCurrentDateTime from './current-date-time.reducer';
import * as fromEventLog from './event-log.reducer';
import * as fromJuku from './juku.reducer';
import * as fromMaintenance from './maintenance.reducer';
import * as fromPlan from './plan.reducer';
import * as fromPlaylist from './playlist.reducer';
import * as fromSearch from './search.reducer';
import * as fromStaticData from './static-data.reducer';
import * as fromTools from './tools.reducer';
import * as fromUniversitySearch from './university-search.reducer';
import * as trySearch from './try-search.reducer';
import * as fromUser from './user.reducer';
import * as fromFindUniversity from './find-university.reducer';
import * as fromCommonIdFindPlaylistOriginalProblemIds from './common-id/common-id-find-playlist-original-problem.reducer';
import * as fromInformation from './information.reducer';
import * as kakomonImageSearch from './kakomon-image-search.reducer';
import * as fromCommonIdTopic from './common-id/common-id-topic.reducer';

export interface RootState {
  core: fromCore.State;
  auth: fromAuth.State;
  commonIdAuth: fromCommonIdAuth.State;
  commonIdGetCustomToken: fromCommonIdGetCustomToken.State;
  staticData: fromStaticData.State;
  search: fromSearch.State;
  plan: fromPlan.State;
  playlist: fromPlaylist.State;
  user: fromUser.State;
  eventLog: fromEventLog.State;
  tools: fromTools.State;
  trySearch: trySearch.State;
  kakomonImageSearch: kakomonImageSearch.State;
  currentDateTime: fromCurrentDateTime.State;
  bookmark: fromBookmark.State;
  juku: fromJuku.State;
  bulkMail: fromBulkMail.State;
  maintenance: fromMaintenance.State;
  commonIdSearch: fromCommonIdSearch.State;
  commonIdPaperBookmark: fromCommonIdPaperBookmark.State;
  commonIdPlaylist: fromCommonIdPlaylist.State;
  commonIdVisitedPaper: fromCommonIdVisitedPaper.State;
  commonIdGetStripeCheckoutUrl: fromCommonIdGetStripeCheckoutUrl.State;
  commonIdUniversityBookmark: fromCommonIdUniversityBookmark.State;
  commonIdFindUniversity: fromCommonIdFindUniversity.State;
  commonIdInformation: fromCommonIdInformation.State;
  commonIdUser: fromCommonIdUser.State;
  commonIdPlaylistBookmark: fromCommonIdPlaylistBookmark.State;
  commonIdAnsweredProblem: fromCommonIdAnsweredProblem.State;
  commonIdBookmarkAndAnsweredPaper: fromCommonIdBookmarkAndAnsweredPaper.State;
  commonIdPurchases: fromCommonIdPurchases.State;
  universitySearch: fromUniversitySearch.State;
  findUniversity: fromFindUniversity.State;
  commonIdFindPlaylistOriginalProblemIds: fromCommonIdFindPlaylistOriginalProblemIds.State;
  information: fromInformation.State;
  commonIdTopic: fromCommonIdTopic.State;
}

export const reducers: ActionReducerMap<RootState> = {
  core: fromCore.reducer,
  auth: fromAuth.reducer,
  commonIdGetCustomToken: fromCommonIdGetCustomToken.reducer,
  commonIdAuth: fromCommonIdAuth.reducer,
  staticData: fromStaticData.reducer,
  search: fromSearch.reducer,
  plan: fromPlan.reducer,
  playlist: fromPlaylist.reducer,
  user: fromUser.reducer,
  eventLog: fromEventLog.reducer,
  tools: fromTools.reducer,
  trySearch: trySearch.reducer,
  kakomonImageSearch: kakomonImageSearch.reducer,
  currentDateTime: fromCurrentDateTime.reducer,
  bookmark: fromBookmark.reducer,
  juku: fromJuku.reducer,
  bulkMail: fromBulkMail.reducer,
  maintenance: fromMaintenance.reducer,
  commonIdSearch: fromCommonIdSearch.reducer,
  commonIdPaperBookmark: fromCommonIdPaperBookmark.reducer,
  commonIdVisitedPaper: fromCommonIdVisitedPaper.reducer,
  commonIdFindUniversity: fromCommonIdFindUniversity.reducer,
  commonIdInformation: fromCommonIdInformation.reducer,
  commonIdGetStripeCheckoutUrl: fromCommonIdGetStripeCheckoutUrl.reducer,
  commonIdUniversityBookmark: fromCommonIdUniversityBookmark.reducer,
  commonIdUser: fromCommonIdUser.reducer,
  commonIdPlaylist: fromCommonIdPlaylist.reducer,
  commonIdPlaylistBookmark: fromCommonIdPlaylistBookmark.reducer,
  commonIdAnsweredProblem: fromCommonIdAnsweredProblem.reducer,
  commonIdBookmarkAndAnsweredPaper: fromCommonIdBookmarkAndAnsweredPaper.reducer,
  commonIdPurchases: fromCommonIdPurchases.reducer,
  universitySearch: fromUniversitySearch.reducer,
  findUniversity: fromFindUniversity.reducer,
  commonIdFindPlaylistOriginalProblemIds: fromCommonIdFindPlaylistOriginalProblemIds.reducer,
  information: fromInformation.reducer,
  commonIdTopic: fromCommonIdTopic.reducer
};

export function clearState(reducer: ActionReducer<RootState>): ActionReducer<RootState> {
  return (state: RootState, action: Action) => {
    if (action.type === signOut.type) state = undefined;
    else if (action.type === commonIdSignOut.type) state = undefined;

    return reducer(state, action);
  };
}

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['staticData'],
    rehydrate: true,
    removeOnUndefined: true,
    restoreDates: false,
    storageKeySerializer: (key: string) => `${environment.firebase.projectId}-${key}`
  })(reducer);
}

export const metaReducers: MetaReducer<RootState>[] = !environment.production
  ? [clearState, localStorageSyncReducer]
  : [clearState, localStorageSyncReducer];
