<app-top-header></app-top-header>
<main class="top-root">
  <div class="top-wrapper">
    <header class="top-header-static">
      <div class="top-header-static-logo md:hidden">
        <img
          src="assets/images/app-logo-for-school-with-company-horizontal.svg"
          alt="入試正解デジタル for School"
          width="510"
          height="98"
        />
      </div>
      <div class="top-header-static-buttons">
        <button
          id="btn-header-installation"
          class="max-xs:hidden max-sm:text-sm"
          mat-flat-button
          color="primary"
          (click)="newInstallationUrlClickHandler()"
        >
          新規導入の申し込み(高校向け)
        </button>
        <button
          id="btn-header-login"
          class="max-xs:text-xs inline-block border-primary text-primary md:w-40"
          mat-stroked-button
          (click)="gotoSearch()"
        >
          ログイン
        </button>
      </div>
    </header>
    <div id="introduction" class="introduction">
      <section class="section-hero">
        <div class="introduction-wrapper">
          <div class="section-hero-picture">
            <div
              class="bg-dot section-hero-picture-item-shadow parallax-target"
              [ngStyle]="{ 'transform': 'translateY(' + getParallaxPositionY(1) + ')' }"
            ></div>
            <div
              class="section-hero-picture-item parallax-target"
              [ngStyle]="{ 'transform': 'translateY(' + getParallaxPositionY(2) + ')' }"
            >
              <picture>
                <source media="(min-width: 780px)" srcset="/assets/images/lp/hero@2x.jpg 2x, /assets/images/lp/hero.jpg" />
                <img srcset="/assets/images/lp/hero.jpg" alt="入試正解デジタル画面イメージ" width="930" height="614" />
              </picture>
            </div>
          </div>

          <div class="section-hero-item">
            <div class="section-hero-title">
              <h1 class="section-hero-title-logo hidden md:block">
                <img src="assets/images/app-logo-for-school-with-company.svg" alt="入試正解デジタル for School" width="510" height="98" />
              </h1>
              <p class="section-hero-title-lead mt-10 mb-10">
                <img
                  srcset="assets/images/lp/hero-copy.svg"
                  alt="『全国大学入試問題正解』Webデータベース 授業での入試問題演習が変わる！"
                  width="510"
                  height="72"
                />
              </p>
            </div>

            <div class="section-hero-buttons mb-16 md:mb-4">
              <button
                id="cv-top-installation"
                class="mt-4 block w-full mat-button-override-large-in-lp cta-button-primary"
                mat-flat-button
                (click)="newInstallationUrlClickHandler()"
              >
                新規導入の申し込み(高校向け)
              </button>
              <button
                id="cv-top-free-trial"
                class="mt-4 block w-full mat-button-override-large-in-lp cta-button-secondary"
                mat-flat-button
                (click)="freeTrialApplicationUrlClickHandler()"
              >
                無料体験の申し込み
              </button>
              <div class="mt-6 md:mt-4 override-link-with-arrow-font-size">
                <app-link-with-arrow (linkClick)="otherInquiryUrlClickHandler()">塾・予備校の導入／その他ご相談</app-link-with-arrow>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-books">
        <div class="introduction-wrapper">
          <h2 class="introduction-lead md:text-right section-books-item">
            <div>80年以上のロングセラー</div>
            <div><span class="text-primary">『全国大学入試問題正解』</span>から</div>
            <div>待望のWebサービスが登場</div>
          </h2>
          <p class="md:w-5/12 md:ml-auto text-base md:text-lg section-books-item mb-4">
            旺文社『<strong>全国大学入試問題正解</strong>』掲載の大学入試過去問を検索できるWebアプリケーションです。
            本サービスは学校様を対象にした有料サービスです。
          </p>
          <div class="section-books-picture">
            <div
              class="bg-dot section-books-picture-item-shadow parallax-target"
              [ngStyle]="{ 'transform': 'translateY(' + getParallaxPositionY(1.5) + ')' }"
            ></div>
            <div
              class="section-books-picture-item parallax-target"
              [ngStyle]="{ 'transform': 'translateY(' + getParallaxPositionY(2) + ')' }"
            >
              <picture>
                <source media="(min-width: 780px)" srcset="/assets/images/lp/books@2x.jpg 2x, /assets/images/lp/books.jpg" />
                <img srcset="/assets/images/lp/books.jpg" alt="全国大学入試問題正解の書影" width="780" height="548" />
              </picture>
            </div>
          </div>
          <div class="md:w-5/12 md:ml-auto mt-4 md:mt-8 section-books-item">
            <div>
              <p class="text-md">
                詳しくは「
                <button class="text-primary underline hover:no-underline" (click)="anchorScrollingHandler('subject')">
                  対応科目
                </button>
                」と「
                <button class="text-primary underline hover:no-underline" (click)="anchorScrollingHandler('price')">プランと料金</button>
                」をご確認ください。
              </p>
            </div>
            <!--
            <div>
              <p>
                対応科目と掲載年数は
                <button class="text-primary underline hover:no-underline" (click)="anchorScrollingHandler('subject')">こちら</button>
                をご参照ください。
              </p>
            </div>
            <div class="font-bold">
              <div class="flex items-center mt-2">
                <mat-icon class="check-icon mr-2" aria-hidden="false">check_circle</mat-icon>
                <span>数学・物理・化学 先行リリース中</span>
              </div>
              <div class="flex items-center mt-2">
                <mat-icon class="check-icon mr-2" aria-hidden="false">check_circle</mat-icon>
                <span>6年分を掲載、今後も追加予定</span>
                <sub class="text-xs ml-1"> (※) </sub>
              </div>
            </div>
            <div class="mt-4 md:mt-6 text-md leading-5">
              <p>
                (※) 掲載科目と掲載年度は
                <a class="text-primary underline hover:no-underline" (click)="anchorScrollingHandler('subject')">こちら</a>
              </p>
            </div>
            -->
          </div>
        </div>
      </section>
      <section class="section-about">
        <div class="section-about-gallery grid grid-cols-2 gap-4 md:gap-8">
          <div
            class="section-about-gallery-col pt-8 md:pt-36 parallax-target"
            [ngStyle]="{ 'transform': 'translateY(' + getParallaxPositionY(3) + ')' }"
          >
            <picture>
              <source media="(min-width: 780px)" srcset="/assets/images/lp/school-1@2x.jpg 2x, /assets/images/lp/school-1.jpg" />
              <img
                class="section-about-gallery-item mb-4 md:mb-8"
                srcset="/assets/images/lp/school-1.jpg"
                width="320"
                height="214"
                alt=""
              />
            </picture>
            <picture>
              <source media="(min-width: 780px)" srcset="/assets/images/lp/school-2@2x.jpg 2x, /assets/images/lp/school-2.jpg" />
              <img
                class="section-about-gallery-item mb-4 md:mb-8"
                srcset="/assets/images/lp/school-2.jpg"
                alt=""
                width="320"
                height="256"
              />
            </picture>
          </div>
          <div class="section-about-gallery-col parallax-target" [ngStyle]="{ 'transform': 'translateY(' + getParallaxPositionY(2) + ')' }">
            <picture>
              <source media="(min-width: 780px)" srcset="/assets/images/lp/school-3@2x.jpg 2x, /assets/images/lp/school-3.jpg" />
              <img
                class="section-about-gallery-item mb-4 md:mb-8"
                srcset="/assets/images/lp/school-3.jpg"
                width="320"
                height="214"
                alt=""
              />
            </picture>
            <picture>
              <source media="(min-width: 780px)" srcset="/assets/images/lp/school-4@2x.jpg 2x, /assets/images/lp/school-4.jpg" />
              <img
                class="section-about-gallery-item mb-4 md:mb-8"
                srcset="/assets/images/lp/school-4.jpg"
                width="320"
                height="214"
                alt=""
              />
            </picture>
          </div>
        </div>
        <div class="introduction-wrapper">
          <h2 class="introduction-lead section-about-item">
            <div>豊富な検索項目から</div>
            <div><span class="text-primary">『大学入試の過去問』</span></div>
            <div>が見つかる</div>
          </h2>
          <p class="md:w-5/12 text-base md:text-lg section-about-item">
            膨大な書籍から時間をかけて問題を探す負担を軽減し、検索項目をチェックするだけで、使いたい問題がすぐに見つかるようになります。<br />
            入試問題演習や授業で使うプリント作成、過去問の研究にお役立ていただけます。
          </p>
        </div>
      </section>
      <div class="section-about-bg parallax-target" [ngStyle]="{ 'transform': 'translateY(' + getParallaxPositionY(3) + ')' }">
        <picture>
          <source media="(min-width: 780px)" srcset="/assets/images/lp/laptop@2x.jpg 2x, /assets/images/lp/laptop.jpg" />
          <img srcset="/assets/images/lp/laptop.jpg" width="750" height="537" alt="" />
        </picture>
      </div>
    </div>

    <section id="service" class="section-basic" data-bg="gray-darker">
      <h2 class="section-basic-header">
        <span class="section-basic-header-lead">SERVICE</span>
        <strong class="section-basic-header-title">サービスの特徴</strong>
      </h2>
      <app-wrapper size="lg">
        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-1 md:gap-8 mb-4">
          <section class="service-item">
            <div class="md:flex md:items-center">
              <div class="grow md:w-1/2">
                <picture>
                  <source
                    media="(min-width: 780px)"
                    srcset="/assets/images/lp/service-picture-1@2x.jpg 2x, /assets/images/lp/service-picture-1.jpg"
                  />
                  <img
                    class="service-item-picture"
                    srcset="/assets/images/lp/service-picture-1@2x.jpg"
                    width="576"
                    height="410"
                    loading="lazy"
                    alt=""
                  />
                </picture>
              </div>
              <div class="grow md:w-1/2 p-8 md:p-12">
                <div class="service-item-title">
                  <div class="service-item-title-icon">
                    <mat-icon aria-hidden="true">grading</mat-icon>
                  </div>
                  <h3 class="service-item-title-text">
                    <span class="inline-block"> 詳細な解答・解説付き<span class="text-sm" style="vertical-align: super;">※</span> </span>
                  </h3>
                </div>
                <div class="mt-4 md:mt-6">
                  <p>
                    「全国大学入試問題正解」掲載の解答・解説を収録！<br />
                    長年、大学・高校・予備校の教育にたずさわっているベテランの先生方による正確でわかりやすい親切な解答・解説をつけています。
                  </p>
                  <p>
                    <small>※ 一部「問題・解答のみ」を掲載</small>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section class="service-item">
            <div class="md:flex md:items-center md:flex-row-reverse">
              <div class="grow md:w-1/2">
                <picture>
                  <source
                    media="(min-width: 780px)"
                    srcset="/assets/images/lp/service-picture-2@2x.jpg 2x, /assets/images/lp/service-picture-2.jpg"
                  />
                  <img
                    class="service-item-picture"
                    srcset="/assets/images/lp/service-picture-2@2x.jpg"
                    width="576"
                    height="410"
                    loading="lazy"
                    alt=""
                  />
                </picture>
              </div>
              <div class="grow md:w-1/2 p-8 md:p-12">
                <div class="service-item-title">
                  <div class="service-item-title-icon">
                    <mat-icon aria-hidden="true">search</mat-icon>
                  </div>
                  <h3 class="service-item-title-text">
                    検索項目が細かく豊富
                  </h3>
                </div>
                <div class="mt-4 md:mt-6">
                  「分野」「大学名」での検索はもちろん、思考力問題や複数年度にわたっての検索も可能です。<br />
                  また科目特有の検索項目（国語の「素材文のジャンル」での絞り込みや、文系科目の「キーワード検索（長文要約や作者など）」）が充実。<br />
                  生徒に適した問題をスムーズに見つけることができます。
                </div>
              </div>
            </div>
          </section>
          <section class="service-item">
            <div class="md:flex md:items-center">
              <div class="grow md:w-1/2">
                <picture>
                  <source
                    media="(min-width: 780px)"
                    srcset="/assets/images/lp/service-picture-3@2x.jpg 2x, /assets/images/lp/service-picture-3.jpg"
                  />
                  <img
                    class="service-item-picture"
                    srcset="/assets/images/lp/service-picture-3@2x.jpg"
                    width="576"
                    height="410"
                    loading="lazy"
                    alt=""
                  />
                </picture>
              </div>
              <div class="grow md:w-1/2 p-8 md:p-12">
                <div class="service-item-title">
                  <div class="service-item-title-icon">
                    <!-- <mat-icon aria-hidden="true">design_services</mat-icon> -->
                    <mat-icon aria-hidden="true">print</mat-icon>
                  </div>
                  <h3 class="service-item-title-text">授業目的にあわせて<br />プリント作成・印刷が可能</h3>
                </div>
                <div class="mt-4 md:mt-6">
                  <p>
                    閲覧・印刷は「試験単位でまとめて」「大問ごとに分けて」より選べます。また、MicrosoftⓇWord形式で問題、解答・解説データをダウンロードできます。<br />
                    志望校対策や苦手分野の集中対策など、授業の目的にあわせてご活用いただけます。
                  </p>
                  <p>
                    <small>※ 一部「問題・解答のみ」を掲載</small>
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section class="service-item">
            <div class="md:flex md:items-center md:flex-row-reverse">
              <div class="grow md:w-1/2">
                <picture>
                  <source
                    media="(min-width: 780px)"
                    srcset="/assets/images/lp/service-picture-4@2x.jpg 2x, /assets/images/lp/service-picture-4.jpg"
                  />
                  <img
                    class="service-item-picture"
                    srcset="/assets/images/lp/service-picture-4@2x.jpg"
                    width="576"
                    height="410"
                    loading="lazy"
                    alt=""
                  />
                </picture>
              </div>
              <div class="grow md:w-1/2 p-8 md:p-12">
                <div class="service-item-title">
                  <div class="service-item-title-icon">
                    <mat-icon aria-hidden="true">insert_drive_file</mat-icon>
                  </div>
                  <h3 class="service-item-title-text">
                    <span class="inline-block">すぐに授業で使える</span>
                    <span class="inline-block">プリント集</span>
                  </h3>
                </div>
                <div class="mt-4 md:mt-6">
                  各大学の過去問から、旺文社編集部や特別講師が選出した授業や講義で使える問題と解答・解説をご用意！<br />
                  「おすすめ問題セット」をぜひ、教材の一つとして利用ください。
                </div>
              </div>
            </div>
          </section>
        </div>
      </app-wrapper>
    </section>

    <section
      id="voice"
      class="section-basic parallax-target-background"
      data-bg="gray-pattern"
      [ngStyle]="{ 'background-position-y': getParallaxPositionY(2) }"
    >
      <h2 class="section-basic-header">
        <span class="section-basic-header-lead">VOICE</span>
        <strong class="section-basic-header-title">先生の声</strong>
      </h2>
      <div class="mb-12">
        <p class="text-center -mt-4">ご採用校の先生方の声を紹介します</p>
      </div>
      <app-wrapper size="md">
        <div class="grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 md:grid-cols-3 md:gap-x-10 md:gap-y-12 mb-6">
          <div class="voice-item">
            <p class="voice-item-message">主に読解問題を生徒の自習用のプリント教材として使っていて、生徒に好評です。</p>
            <div class="voice-item-person">
              <div class="voice-item-person-avatar">
                <img src="/assets/images/lp/voice-thumb-1.png" alt="英語" />
              </div>
              <div class="voice-item-person-name">英語の先生</div>
            </div>
          </div>
          <div class="voice-item">
            <p class="voice-item-message">
              生徒に追加演習として入試正解デジタルから出題することがあります。もう1問を出題したいときに検索しやすく便利です。大学名を見て、難しい問題にもチャレンジしようという生徒の意識が高くなりました。
            </p>
            <div class="voice-item-person">
              <div class="voice-item-person-avatar">
                <img src="/assets/images/lp/voice-thumb-2.png" alt="数学" />
              </div>
              <div class="voice-item-person-name">数学の先生</div>
            </div>
          </div>
          <div class="voice-item">
            <p class="voice-item-message">
              解説が充実している点は、他社商品にはないため大変助かります。操作に慣れてきたら、さらに活用できそうです。
            </p>
            <div class="voice-item-person">
              <div class="voice-item-person-avatar">
                <img src="/assets/images/lp/voice-thumb-3.png" alt="生物" />
              </div>
              <div class="voice-item-person-name">生物の先生</div>
            </div>
          </div>
          <div class="voice-item">
            <p class="voice-item-message">
              分野別・難度別・年度別・国公私別・地域別に問題を検索できることや、問題と解答を別に印刷できることが便利です。
            </p>
            <div class="voice-item-person">
              <div class="voice-item-person-avatar">
                <img src="/assets/images/lp/voice-thumb-4.png" alt="物理" />
              </div>
              <div class="voice-item-person-name">物理の先生</div>
            </div>
          </div>
          <div class="voice-item">
            <p class="voice-item-message">授業内での演習や、長期休みの講習で使用しています。デジタルで検索できることは、本より便利です。</p>
            <div class="voice-item-person">
              <div class="voice-item-person-avatar">
                <img src="/assets/images/lp/voice-thumb-5.png" alt="世界史" />
              </div>
              <div class="voice-item-person-name">世界史の先生</div>
            </div>
          </div>
          <div class="voice-item">
            <p class="voice-item-message">
              書籍で調べるより格段に速くなり、業務の効率化につながっています。大学別に出てくるので、大学ごとの演習にも適しているように思います。
            </p>
            <div class="voice-item-person">
              <div class="voice-item-person-avatar">
                <img src="/assets/images/lp/voice-thumb-6.png" alt="数学" />
              </div>
              <div class="voice-item-person-name">数学の先生</div>
            </div>
          </div>
        </div>
      </app-wrapper>
    </section>

    <section id="subject" class="section-basic" data-bg="gray">
      <h2 class="section-basic-header">
        <span class="section-basic-header-lead">SUBJECT</span>
        <strong class="section-basic-header-title">対応科目</strong>
      </h2>
      <app-wrapper size="md">
        <div>
          <div class="flex items-center justify-center md:justify-end gap-2 text-xs md:text-sm mb-2 md:mb-1">
            <div class="flex items-center">
              <span>
                <span class="icon-subject-info" matTooltip="問題・解答掲載あり" matTooltipPosition="above"
                  ><mat-icon class="material-icons-outlined" aria-label="問題・解答掲載あり">description</mat-icon></span
                >
              </span>
              <span>
                問題・解答掲載あり
              </span>
            </div>
            <div class="flex items-center">
              <span>
                <span class="icon-subject-info" matTooltip="Wordデータ掲載あり" matTooltipPosition="above"
                  ><app-icon-word aria-label="Wordデータ掲載あり"></app-icon-word
                ></span>
              </span>
              <span>Wordデータ掲載あり</span>
            </div>
          </div>

          <app-overflow-wrapper-x>
            <table class="table-subject" style="min-width: 680px;">
              <tr>
                <th class="bg-gray-30 border-t-0 border-l-0" style="min-width:7em;"></th>
                <th class="text-xs md:text-sm">2024</th>
                <th class="text-xs md:text-sm">2023</th>
                <th class="text-xs md:text-sm">2022</th>
                <th class="text-xs md:text-sm">2021</th>
                <th class="text-xs md:text-sm">2020</th>
                <th class="text-xs md:text-sm">2019</th>
                <th class="text-xs md:text-sm">2018</th>
                <th class="text-xs md:text-sm">2017</th>
                <th class="text-xs md:text-sm">2016</th>
                <th class="text-xs md:text-sm">2015</th>
                <th class="text-xs md:text-sm">対応書籍</th>
              </tr>
              <tr>
                <th>数学</th>
                <td rowspan="3" colspan="6">
                  <span class="icon-subject-info px-1" matTooltip="問題・解答掲載あり" matTooltipPosition="above"
                    ><mat-icon class="material-icons-outlined" aria-label="問題・解答掲載あり">description</mat-icon></span
                  >
                  <span class="icon-subject-info px-1" matTooltip="Wordデータ掲載あり" matTooltipPosition="above"
                    ><app-icon-word aria-label="Wordデータ掲載あり"></app-icon-word
                  ></span>
                </td>
                <td rowspan="3" colspan="4">
                  <span class="icon-subject-info px-1" matTooltip="問題・解答掲載あり" matTooltipPosition="above"
                    ><mat-icon class="material-icons-outlined" aria-label="問題・解答掲載あり">description</mat-icon></span
                  >
                </td>
                <td class="text-xs md:text-sm">私立大、国公立大、追加掲載編</td>
              </tr>
              <tr>
                <th>物理</th>
                <td class="text-xs md:text-sm">物理、理科追加掲載編（物理）</td>
              </tr>
              <tr>
                <th>化学</th>
                <td class="text-xs md:text-sm">化学、理科追加掲載編（化学）</td>
              </tr>
              <tr>
                <th>生物</th>
                <td colspan="6">
                  <span class="icon-subject-info px-1" matTooltip="問題・解答掲載あり" matTooltipPosition="above"
                    ><mat-icon class="material-icons-outlined" aria-label="問題・解答掲載あり">description</mat-icon></span
                  >
                  <span class="icon-subject-info px-1" matTooltip="Wordデータ掲載あり" matTooltipPosition="above"
                    ><app-icon-word aria-label="Wordデータ掲載あり"></app-icon-word
                  ></span>
                </td>
                <td>
                  <span class="icon-subject-info px-1" matTooltip="問題・解答掲載あり" matTooltipPosition="above"
                    ><mat-icon class="material-icons-outlined" aria-label="問題・解答掲載あり">description</mat-icon></span
                  >
                </td>
                <td colspan="3" class="cell-disabled"></td>
                <td class="text-xs md:text-sm">生物、理科追加掲載編（生物）</td>
              </tr>
              <tr>
                <th>国語 <span class="text-primary">＊</span></th>
                <td rowspan="3" colspan="6">
                  <span class="icon-subject-info px-1" matTooltip="問題・解答掲載あり" matTooltipPosition="above"
                    ><mat-icon class="material-icons-outlined" aria-label="問題・解答掲載あり">description</mat-icon></span
                  >
                  <span class="icon-subject-info px-1" matTooltip="Wordデータ掲載あり" matTooltipPosition="above"
                    ><app-icon-word aria-label="Wordデータ掲載あり"></app-icon-word
                  ></span>
                </td>
                <td rowspan="3" colspan="4" class="cell-disabled"></td>
                <td class="text-xs md:text-sm">私立大、国公立大、追加掲載編</td>
              </tr>
              <tr>
                <th>日本史</th>
                <td class="text-xs md:text-sm">日本史、地歴追加掲載編（日本史）</td>
              </tr>
              <tr>
                <th>世界史</th>
                <td class="text-xs md:text-sm">世界史、地歴追加掲載編（世界史）</td>
              </tr>
              <tr>
                <th>地理</th>
                <td rowspan="3" colspan="5">
                  <span class="icon-subject-info px-1" matTooltip="問題・解答掲載あり" matTooltipPosition="above"
                    ><mat-icon class="material-icons-outlined" aria-label="問題・解答掲載あり">description</mat-icon></span
                  >
                  <span class="icon-subject-info px-1" matTooltip="Wordデータ掲載あり" matTooltipPosition="above"
                    ><app-icon-word aria-label="Wordデータ掲載あり"></app-icon-word
                  ></span>
                </td>
                <td rowspan="3" colspan="5" class="cell-disabled"></td>
                <td class="text-xs md:text-sm">地理、地歴追加掲載編（地理）</td>
              </tr>
              <tr>
                <th>政治・経済</th>
                <td class="text-xs md:text-sm">政治・経済</td>
              </tr>
              <tr>
                <th>英語</th>
                <td class="text-xs md:text-sm">私立大、国公立大、追加掲載編</td>
              </tr>
            </table>
          </app-overflow-wrapper-x>

          <p class="mt-2 text-sm">
            <span class="text-primary">＊</span> 国語の2019年度は古文・漢文のみの掲載で、現代文の掲載はありません。<br />
            <br />
            ※ 著作権等の関係で、一部閲覧できない問題もございます。<br />
            ※ 年度についての詳細は<button class="text-primary underline hover:no-underline" (click)="gotoFaq('faq-11')">こちらのFAQ</button
            >をご覧ください。<br />
            ※ 24年度入試の掲載大学情報は<button
              class="text-primary underline hover:no-underline"
              (click)="universitiesListDownloadClickHandler()"
            >
              こちらのPDFファイル</button
            >をご覧ください。<br />
            ※ 英語、国語（現代文）の20年度入試は、25年3月下旬に掲載を終了します。<br />
            ※ 25年度入試は、25年12月頃に搭載予定です。<br />
          </p>
        </div>
      </app-wrapper>
    </section>

    <section id="price" class="section-basic" data-bg="white">
      <h2 class="section-basic-header">
        <span class="section-basic-header-lead">PLAN / PRICE</span>
        <strong class="section-basic-header-title">プランと料金</strong>
      </h2>
      <div>
        <app-wrapper size="md">
          <div class="text-center mb-8">
            <p>入試正解デジタル for School は、学校のご利用目的にあわせて選べる２つのプランをご用意しています。</p>
          </div>
          <table class="table-price">
            <thead>
              <tr>
                <th class="w-2/12 bg-white border-transparent"></th>
                <th class="w-5/12" data-cell-type="zenkamoku">全科目セットプラン</th>
                <th class="w-5/12" data-cell-type="kamoku">科目プラン</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>料金</th>
                <td data-cell-type="zenkamoku"><strong>330,000円／年</strong>（税込）</td>
                <td data-cell-type="kamoku">
                  <strong>88,000円／年</strong>（税込）<br />
                  <small>※ 2科目の場合、88,000円 × 2科目</small>
                </td>
              </tr>
              <tr>
                <th>特徴</th>
                <td class="text-left text-xs md:text-md" data-cell-type="zenkamoku">
                  学校内のすべての先生がご利用いただけるプランです。学校で一括採用をご検討の場合におすすめです。
                </td>
                <td class="text-left text-xs md:text-md" data-cell-type="kamoku">
                  科目ごとにお申し込みが可能で、人数によって価格も変わります。必要な科目に絞ってのお申し込みができます。
                </td>
              </tr>
              <tr>
                <th>
                  利用人数<br />
                  （先生）
                </th>
                <td data-cell-type="zenkamoku">
                  校内無制限<br />
                  <small>※ 必要アカウント数を申請いただきます</small>
                </td>
                <td data-cell-type="kamoku">
                  10人まで<br />
                  <small>※ 11人以上は1人あたり8,800円</small>
                </td>
              </tr>
              <tr>
                <th>
                  利用科目
                </th>
                <td data-cell-type="zenkamoku">
                  10科目すべて
                </td>
                <td data-cell-type="kamoku">
                  1科目ごと
                </td>
              </tr>
            </tbody>
          </table>
          <p class="mt-2 text-sm">
            ※ プランによる機能差はありません。
          </p>
          <div class="mx-auto max-w-2xl">
            <div class="mt-16 grid gap-4 md:grid-cols-2">
              <button
                id="cv-in-plan-installation"
                class="mat-button-override-large-in-lp cta-button-primary"
                mat-flat-button
                (click)="newInstallationUrlClickHandler()"
              >
                新規導入の申し込み(高校向け)
              </button>
              <button
                id="cv-in-plan-free-trial"
                class="block w-full mat-button-override-large-in-lp cta-button-secondary"
                mat-flat-button
                (click)="freeTrialApplicationUrlClickHandler()"
              >
                無料体験の申し込み
              </button>
            </div>
            <div class="mt-6 md:mt-4 mb-4 md:flex md:justify-center override-link-with-arrow-font-size override-link-with-arrow-color">
              <app-link-with-arrow (linkClick)="otherInquiryUrlClickHandler()">塾・予備校の導入／その他ご相談</app-link-with-arrow>
            </div>
          </div>
        </app-wrapper>
      </div>
    </section>

    <section id="flow" class="section-basic" data-bg="gray">
      <h2 class="section-basic-header">
        <span class="section-basic-header-lead">FLOW</span>
        <strong class="section-basic-header-title">導入の流れ</strong>
      </h2>

      <app-wrapper size="md">
        <div class="sm:flex mb-4">
          <section class="flow-item">
            <h3 class="flow-item-title">
              <span>STEP 1</span>
              <strong>新規申込</strong>
            </h3>
            <div class="flow-item-body">
              <p>
                <a class="text-primary underline hover:no-underline" (click)="newInstallationUrlClickHandler()" role="button" tabindex="0"
                  >新規導入の申し込みフォーム</a
                >
                に、貴団体の基本情報、管理者情報、請求先情報などを入力いただきます。
              </p>
              <p class="text-sm text-primary -mb-1">※ お申し込みは旺文社が直接承ります。</p>
            </div>
          </section>

          <div class="flow-item-arrow">
            <mat-icon class="sm:hidden block" aria-hidden="true">arrow_drop_down</mat-icon>
            <mat-icon class="hidden sm:block" aria-hidden="true">arrow_right</mat-icon>
          </div>

          <section class="flow-item">
            <h3 class="flow-item-title">
              <span>STEP 2</span>
              <strong>利用開始</strong>
            </h3>
            <div class="flow-item-body">
              <p>
                管理者の方にアカウントを発行し、メールでご連絡いたします。<strong class="text-bold text-primary"
                  >申込日から利用開始日まで最短1週間</strong
                >です。
              </p>
            </div>
          </section>

          <div class="flow-item-arrow">
            <mat-icon class="sm:hidden block" aria-hidden="true">arrow_drop_down</mat-icon>
            <mat-icon class="hidden sm:block" aria-hidden="true">arrow_right</mat-icon>
          </div>

          <section class="flow-item">
            <h3 class="flow-item-title">
              <span>STEP 3</span>
              <strong>ユーザー登録</strong>
            </h3>
            <div class="flow-item-body">
              <p>ログイン後、アカウント管理画面から、その他の先生を登録していただきます。</p>
              <p class="text-sm text-primary -mb-1">※ アカウントご登録の際に、個々の先生のメールアドレスが必要です。</p>
            </div>
          </section>
        </div>
      </app-wrapper>
    </section>

    <section id="faq" class="section-basic" data-bg="white">
      <h2 class="section-basic-header">
        <span class="section-basic-header-lead">FAQ</span>
        <strong class="section-basic-header-title">よくあるご質問</strong>
      </h2>
      <app-wrapper size="md">
        <div class="-mx-2 mx-0">
          <div class="logs" *ngFor="let faqListItem of faqList">
            <div [id]="faqListItem.id" class="mb-4 md:mb-6">
              <app-faq-list-item [isExpanded]="faqListItem.isExpanded" [questionTitle]="faqListItem.title">
                <div class="mt-0" [innerHTML]="faqListItem.body"></div>
              </app-faq-list-item>
            </div>
          </div>
        </div>
      </app-wrapper>
    </section>

    <section id="cv-bottom" class="bottom-cta">
      <app-wrapper size="sm">
        <h2 class="bottom-cta-headline">
          『入試正解デジタル for School』は<br />
          こちらからお申し込みください
        </h2>
        <div class="mx-auto max-w-2xl">
          <div class="mt-8 grid gap-4 md:grid-cols-2">
            <button
              id="cv-bottom-installation"
              class="mat-button-override-large-in-lp cta-button-primary-in-bottom"
              mat-flat-button
              (click)="newInstallationUrlClickHandler()"
            >
              新規導入の申し込み(高校向け)
            </button>
            <button
              id="cv-bottom-free-trial"
              class="block w-full mat-button-override-large-in-lp cta-button-secondary-in-bottom"
              mat-flat-button
              (click)="freeTrialApplicationUrlClickHandler()"
            >
              無料体験の申し込み
            </button>
          </div>
          <div class="mt-6 md:mt-4 md:flex md:justify-center override-link-with-arrow-font-size override-link-with-arrow-color-in-bottom">
            <app-link-with-arrow (linkClick)="otherInquiryUrlClickHandler()">塾・予備校の導入／その他ご相談</app-link-with-arrow>
          </div>
        </div>
      </app-wrapper>
    </section>
    <app-footer></app-footer>
  </div>
</main>
<div id="script"></div>
