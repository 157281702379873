import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { Observable, filter, take, Subscription } from 'rxjs';
import { ViewportScroller } from '@angular/common';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';
import { MEMBER_CONTACT_URL } from '../../../../resources/config';
import { CONTACT_GOOGLE_FORM_URL, REQUEST_GOOGLE_FORM_URL } from '../../../../resources/common-id-config';
import { Store } from '@ngrx/store';
import { RootState } from '../../../../reducers';
import { getCommonIdSignedInUser } from '../../../../selectors/common-id/common-id-auth.selectors';
import { CommonIdUser } from '../../../../models/common-id/common-id-user';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { openWindow, setTitle } from '../../../../actions/core.actions';
import { CommonIdContactUtil } from '../../../../utils/common-id/common-id-contact-util';
import { COMMON_ID_FAQS, CommonIdFaq } from '../../../../resources/common-id/faqs';

@Component({
  selector: 'app-common-id-help',
  templateUrl: './help.component.html',
  styleUrls: ['./help.component.scss']
})
export class CommonIdHelpComponent implements OnInit, OnDestroy, AfterViewInit {
  memberContactUrl: string = MEMBER_CONTACT_URL;
  userManualUrl: string = environment.manualPdfFiles.userManual;
  adminManualUrl: string = environment.manualPdfFiles.adminManual;
  faqs: CommonIdFaq[];

  private fragmentSubscription: Subscription;
  private LOG_SOURCE = this.constructor.name;
  private title = 'ヘルプ';
  private signedInUser$: Observable<CommonIdUser>;

  constructor(
    private store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private viewportScroller: ViewportScroller,
    @Inject(WINDOW_OBJECT) private window: Window
  ) {}

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));

    this.signedInUser$ = this.store.select(getCommonIdSignedInUser).pipe(
      filter<CommonIdUser>(it => it != null && it !== 'none'),
      take(1)
    );

    this.faqs = JSON.parse(JSON.stringify(COMMON_ID_FAQS.filter(faq => faq.helpFlag)));
    this.faqs.forEach((faq, index) => {
      this.faqs[index].body = faq.body.replace(/data-href="#faq-(\d+)"/g, 'href="/c/help#faq-$1"');
    });
    this.signedInUser$.subscribe(user => {
      this.faqs.forEach((faq, index) => {
        const url = CommonIdContactUtil.getGoogleFormUrl(CONTACT_GOOGLE_FORM_URL, user.commonId, this.window.navigator.userAgent);
        this.faqs[index].body = faq.body.replace('<contact-url>', url);
      });
    });

    this.fragmentSubscription = this.activatedRoute.fragment.subscribe(fragment => {
      const targetFaq = this.faqs.find(faq => faq.id === fragment);
      if (targetFaq) {
        targetFaq.isExpanded = true;
        setTimeout(() => this.viewportScroller.scrollToAnchor(fragment), 300);
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy() {
    if (this.fragmentSubscription) {
      this.fragmentSubscription.unsubscribe();
    }
  }

  gotoContact() {
    this.signedInUser$.subscribe(user => {
      const url = CommonIdContactUtil.getGoogleFormUrl(CONTACT_GOOGLE_FORM_URL, user.commonId, this.window.navigator.userAgent);
      this.store.dispatch(openWindow({ url }));
    });
  }

  gotoCommonIdFaq() {
    this.store.dispatch(openWindow({ url: environment.commonIdConfig.faqUrl }));
  }

  gotoPassNaviFaq() {
    this.store.dispatch(openWindow({ url: environment.passNaviConfig.faqUrl }));
  }

  gotoRequest() {
    this.signedInUser$.subscribe(user => {
      const url = CommonIdContactUtil.getRequestGoogleFormUrl(REQUEST_GOOGLE_FORM_URL, user.commonId);
      this.store.dispatch(openWindow({ url }));
    });
  }
}
