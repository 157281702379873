import { createAction, props } from '@ngrx/store';
import { AppError } from 'src/app/errors/app-error';
import { CommonIdGetTopicRequest, CommonIdGetTopicResponse } from 'src/app/models/common-id/common-id-topic';

export const commonIdGetTopic = createAction('[CommonIdTopic] CommonId Get Topic', props<{ request: CommonIdGetTopicRequest }>());
export const commonIdGetTopicSuccess = createAction(
  '[CommonIdTopic] CommonId Get Topic Success',
  props<{ response: CommonIdGetTopicResponse }>()
);
export const commonIdGetTopicFailure = createAction('[CommonIdTopic] Common Id Get Topic Failure', props<{ error: AppError }>());
export const initializeCommonIdGetTopicState = createAction('[CommonIdTopic] Initialize CommonId Get Topic State');
