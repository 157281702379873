import { AfterViewInit, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, take } from 'rxjs/operators';

import { navigate, openWindow, setTitle } from '../../../../actions/core.actions';
import { setCommonIdBrowserTitle } from '../../../../actions/common-id/common-id-core.actions';
import { commonIdGetTopic, initializeCommonIdGetTopicState } from '../../../../actions/common-id/common-id-topic.actions';
import { RootState } from '../../../../reducers';
import * as CommonIdGetTopicSelectors from 'src/app/selectors/common-id/common-id-topic.selectors';

import { CommonIdTopic, CommonIdGetTopicRequest, CommonIdGetTopicResponse } from 'src/app/models/common-id/common-id-topic';
import { WINDOW_OBJECT } from '../../../../utils/injection-tokens';
import { Dates } from '../../../../utils/dates';
import { RoutingPathResolver } from '../../../../app-routing-path-resolver';
import { enter } from '../../../../resources/animations';

@Component({
  selector: 'app-common-id-topic',
  templateUrl: './topic.component.html',
  styleUrls: ['./topic.component.scss'],
  animations: [enter]
})
export class CommonIdTopicComponent implements OnInit, AfterViewInit, OnDestroy {
  topic: CommonIdTopic;
  isTopicLoading = true;

  private LOG_SOURCE = this.constructor.name;
  private title = 'トピック';

  constructor(
    private store: Store<RootState>,
    private activatedRoute: ActivatedRoute,
    private sanitizer: DomSanitizer,
    @Inject(WINDOW_OBJECT) private window: Window
  ) {}

  ngOnInit() {
    this.store.dispatch(setCommonIdBrowserTitle({ subTitle: this.title }));
    setTimeout(() => this.store.dispatch(setTitle({ title: this.title })));
    this.setUpTopic();
  }

  ngAfterViewInit() {
    setTimeout(() => window.scrollTo(0, 0));
  }

  ngOnDestroy(): void {
    this.store.dispatch(initializeCommonIdGetTopicState());
  }

  sanitize(text) {
    return this.sanitizer.bypassSecurityTrustHtml(text);
  }

  /* リンクボタンはコメントアウトしておく
  gotoContact(linkUrl: string) {
    this.store.dispatch(openWindow({ url: linkUrl }));
  }
  */

  private setUpTopic() {
    this.store.dispatch(initializeCommonIdGetTopicState());

    const topicId: string = this.activatedRoute.snapshot.paramMap.get('topicId');
    const request: CommonIdGetTopicRequest = { topicId };
    this.store.dispatch(commonIdGetTopic({ request }));

    this.store
      .select(CommonIdGetTopicSelectors.getCommonIdTopic)
      .pipe(
        filter(it => it != null),
        take(1)
      )
      .subscribe(result => {
        if (result.success) {
          this.topic = this.getEditedTopic(result.response);
          this.isTopicLoading = false;
        } else {
          // エラーの場合はBtoCのトップ画面に遷移
          this.store.dispatch(navigate({ url: RoutingPathResolver.resolveCommonIdTop() }));
        }
      });
  }

  private getEditedTopic(topic: CommonIdGetTopicResponse): CommonIdTopic {
    // 発行日の編集を行う
    const publishedAt = Dates.simple4YmdStringFromIso(topic.publishedAt);
    const result: CommonIdTopic = {
      id: topic.id,
      publishedAt,
      title: topic.title,
      body: topic.body,
      linkLabel: topic.linkLabel,
      linkUrl: topic.linkUrl
    };
    return result;
  }
}
