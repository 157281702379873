<header class="sign-in-header">
  <div class="sign-in-header-top">
    <div class="sign-in-header-logo">
      <img src="assets/images/app-logo-with-company.svg" alt="旺文社 入試正解デジタル" />
    </div>
    <div
      class="sign-in-header-user-info"
      tabindex="1"
      (touchend)="touchendUserInfoDropdown()"
      (mouseenter)="hoverUserInfoDropdown()"
      (mouseleave)="blurUserInfoParent()"
      (focus)="focusUserInfoParent()"
      (blur)="blurUserInfoParent()"
      [ngClass]="{
        isShow: showUserInfoDropdown(),
        isHover: showUserInfoDropdown()
      }"
    >
      <div class="manabi-id">
        <span class="manabi-id-badge" *ngIf="newInformation$ | async"></span>
        <div class="flex items-center justify-center h-full">
          <div class="text-center">
            <div class="manabi-id-name">
              <!--
                TODO: 名前の出力について
                1つのstring でもOKですし、 姓名でspan単位で分けてもOKです
              -->
              {{ nickname$ | async }}
            </div>
            <div class="manabi-id-logo">
              <img src="assets/images/logo-manabi-id-mark.svg" alt="" />
            </div>
          </div>
          <div class="manabi-id-dropdown-icon"><mat-icon aria-hidden="false">expand_more</mat-icon></div>
        </div>
      </div>

      <div class="sign-in-header-user-info-dropdown">
        <div class="user-info-in-dropdown">
          <div class="w-full">
            <!-- ▼ TODO: プレミアムのみ表示 -->
            <div class="user-info-in-dropdown-premium" *ngIf="isPremium$ | async">
              <app-icon-premium></app-icon-premium>
              プレミアム会員
            </div>
            <!-- △ TODO: プレミアムのみ表示 -->

            <div class="user-info-in-dropdown-name">
              <span>{{ nickname$ | async }}</span>
              <!-- <span>長い名前の場合長い名前の場合長い名前の場合長い名前の場合</span> -->
              <span class="san">さん</span>
            </div>
          </div>
        </div>

        <!-- ▼ TODO: プレミアムのみ表示 -->
        <hr class="border-t border-gray-50" *ngIf="isPremium$ | async" />
        <!-- △ TODO: プレミアムのみ表示 -->

        <!-- ▼ TODO: プレミアムのみ非表示 -->
        <button
          class="button-go-premium"
          matRipple
          tabindex="1"
          (click)="showPurchase()"
          (focus)="focusUserInfoItem(0)"
          (blur)="blurUserInfoItem(0)"
          *ngIf="(isPremium$ | async) === false"
        >
          <app-icon-premium class="button-go-premium-icon"></app-icon-premium>
          <p>
            プレミアムに登録して<br />
            過去問をもっと見る!!
          </p>
          <mat-icon aria-hidden="false" class="button-go-premium-arrow">keyboard_arrow_right</mat-icon>
        </button>
        <!-- △ TODO: プレミアムのみ非表示 -->

        <div class="mt-2">
          <!--
          <button
            class="button-item"
            matRipple
            tabindex="1"
            (click)="showMylist()"
            (focus)="focusUserInfoItem(0)"
            (blur)="blurUserInfoItem(0)"
          >
            <mat-icon class="icon" fontSet="material-icons-outlined">fact_check</mat-icon>
            <span class="label">マイリスト</span>
          </button>
          -->
          <button
            class="button-item sm:hidden"
            matRipple
            tabindex="1"
            (click)="showInformation()"
            (focus)="focusUserInfoItem(0)"
            (blur)="blurUserInfoItem(0)"
          >
            <mat-icon class="icon">notifications</mat-icon>
            <span class="label relative">
              お知らせ
              <!-- ▼ バッジ -->
              <span class="badge-in-button-item" *ngIf="newInformation$ | async"></span>
              <!-- △ バッジ -->
            </span>
          </button>
          <button
            class="button-item"
            matRipple
            tabindex="1"
            (click)="gotoProfile()"
            (focus)="focusUserInfoItem(0)"
            (blur)="blurUserInfoItem(0)"
          >
            <mat-icon class="icon">manage_accounts</mat-icon>
            <span class="label">
              プロフィール確認
            </span>
          </button>
          <button
            class="button-item"
            matRipple
            tabindex="1"
            (click)="showPayments()"
            (focus)="focusUserInfoItem(0)"
            (blur)="blurUserInfoItem(0)"
          >
            <mat-icon class="icon">credit_card</mat-icon>
            <span class="label">購入履歴と利用期限</span>
          </button>
          <button
            class="button-item"
            matRipple
            tabindex="1"
            (click)="showHelp()"
            (focus)="focusUserInfoItem(0)"
            (blur)="blurUserInfoItem(0)"
          >
            <mat-icon class="icon">help_outline</mat-icon>
            <span class="label">
              よくある質問・ご意見
            </span>
          </button>
          <button
            class="button-item"
            (click)="signOut()"
            matRipple
            tabindex="1"
            (focus)="focusUserInfoItem(0)"
            (blur)="blurUserInfoItem(0)"
          >
            <mat-icon class="icon">logout</mat-icon>
            <span class="label">サインアウト</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="border-b border-gray-50"></div>
  <app-overflow-wrapper-x>
    <nav class="sign-in-gnav">
      <div mat-tab-nav-bar class="sign-in-gnav-list" [disableRipple]="true">
        <button
          mat-tab-link
          tabindex="1"
          class="sign-in-gnav-list-item"
          (click)="showSearch()"
          [active]="isCurrentSearch()"
          matRipple
          [matRippleUnbounded]="true"
          [matRippleRadius]="30"
        >
          <mat-icon class="icon">search</mat-icon>
          大学から探す
        </button>
        <button
          mat-tab-link
          tabindex="1"
          class="sign-in-gnav-list-item"
          (click)="showSearchByCategories()"
          [active]="isCurrentSearchByCategories()"
          matRipple
          [matRippleUnbounded]="true"
          [matRippleRadius]="30"
        >
          <mat-icon class="icon">manage_search</mat-icon>
          分野から探す
        </button>
        <button
          mat-tab-link
          tabindex="1"
          class="sign-in-gnav-list-item"
          (click)="showPlaylists()"
          [active]="isCurrentPlaylists()"
          matRipple
          [matRippleUnbounded]="true"
          [matRippleRadius]="30"
        >
          <mat-icon class="icon">view_module</mat-icon>
          おすすめ問題セット
        </button>
        <button
          mat-tab-link
          tabindex="1"
          class="sign-in-gnav-list-item"
          (click)="showMylist()"
          [active]="isCurrentMylist()"
          matRipple
          [matRippleUnbounded]="true"
          [matRippleRadius]="30"
        >
          <mat-icon class="icon" fontSet="material-icons-outlined">fact_check</mat-icon>
          マイリスト
        </button>
      </div>
      <div class="sign-in-gnav-icons">
        <div class="sign-in-gnav-icons-list">
          <button
            class="sign-in-gnav-icons-list-button"
            mat-icon-button
            matTooltip="お知らせ"
            (click)="showInformation()"
            matBadge="!"
            matBadgeSize="small"
            [matBadgeHidden]="(newInformation$ | async) !== true"
          >
            <mat-icon class="icon" fontSet="material-icons-outlined">notifications</mat-icon>
          </button>
          <!--
          <button class="sign-in-gnav-icons-list-button" mat-icon-button matTooltip="ヘルプ" (click)="showHelp()">
            <mat-icon class="icon">help_outline</mat-icon>
          </button>
          -->
        </div>
      </div>
    </nav>
  </app-overflow-wrapper-x>
</header>
