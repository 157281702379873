import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { RootState } from 'src/app/reducers';
import { getSignedInUser } from 'src/app/selectors/auth.selectors';

import { User } from 'src/app/models/user';
import { ContactUtil } from 'src/app/utils/contact-util';
import { REQUEST_GOOGLE_FORM_URL } from 'src/app/resources/config';

@Component({
  selector: 'app-bottom-toast-banner',
  templateUrl: './bottom-toast-banner.component.html',
  styleUrls: ['./bottom-toast-banner.component.scss']
})
export class BottomToastBannerComponent implements OnInit, OnDestroy {
  isBannerVisible = true;
  isSearchRoute = false;
  signedInUser$: Observable<User>;
  requestFormUrl: string;

  private readonly storageKey = 'bottomToastBannerClosedAtToS';
  private readonly hideDuration = 14 * 24 * 60 * 60 * 1000; // 2週間をミリ秒で
  private routerSubscription: Subscription;

  constructor(private router: Router, private store: Store<RootState>) {}

  ngOnInit() {
    this.setUpSignedInUser();
    this.setUpRequestFormUrl();

    this.checkBannerVisibility();
    this.checkIfSearchRoute(this.router.url); // 初期ロード時に現在のURLをチェック

    this.routerSubscription = this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      this.checkIfSearchRoute(event.urlAfterRedirects);
    });
  }

  ngOnDestroy() {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }

  private setUpSignedInUser() {
    this.signedInUser$ = this.store.select(getSignedInUser).pipe(
      filter<User>(it => it != null),
      take(1)
    );
  }

  private setUpRequestFormUrl() {
    this.signedInUser$.subscribe(user => {
      this.requestFormUrl = ContactUtil.getRequestGoogleFormUrl(REQUEST_GOOGLE_FORM_URL, encodeURIComponent(user.email));
    });
  }

  private checkBannerVisibility() {
    const closedAt = localStorage.getItem(this.storageKey);
    if (closedAt) {
      const closedTime = parseInt(closedAt, 10);
      const currentTime = Date.now();
      if (currentTime - closedTime < this.hideDuration) {
        this.isBannerVisible = false;
      }
    }
  }

  private checkIfSearchRoute(url: string) {
    // AngularのRouter.urlにはクエリパラメータが含まれるため、パス部分のみを取得
    const urlTree = this.router.parseUrl(url);
    const path = urlTree.root.children.primary?.segments.map(segment => segment.path).join('/') || '';

    // 正確に '/search' と一致するかをチェック
    this.isSearchRoute = path === 'search';
  }

  closeBanner() {
    this.isBannerVisible = false;
    const currentTime = Date.now();
    localStorage.setItem(this.storageKey, currentTime.toString());
  }
}
