<app-wrapper size="lg" isFitWithSmallScreen="true">
  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon" aria-hidden="true">mail</mat-icon>
        お問い合わせ・ご意見
      </app-card-content-head>
      <app-wrapper size="fluid">
        <app-content-row>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4 md:gap-4">
            <button class="manual-item" matRipple (click)="gotoContact()">
              <div>
                <div class="manual-item-title">
                  <mat-icon class="icon" aria-hidden="true">mail</mat-icon>
                  入試正解デジタル内のお問い合わせ
                </div>
                <div class="manual-item-description">
                  過去問の掲載内容／システムの機能／有料プラン／サービス全般に関することなどはこちら
                </div>
              </div>
            </button>
            <button class="manual-item" matRipple (click)="gotoCommonIdFaq()">
              <div>
                <div class="manual-item-title">
                  <mat-icon class="icon" aria-hidden="true">mail</mat-icon>
                  旺文社まなびIDのお問い合わせ
                </div>
                <div class="manual-item-description">
                  パスワード再設定／アカウント情報を変更／退会などについてのご質問はこちら
                </div>
              </div>
            </button>
            <button class="manual-item" matRipple (click)="gotoPassNaviFaq()">
              <div>
                <div class="manual-item-title">
                  <mat-icon class="icon" aria-hidden="true">mail</mat-icon>
                  大学受験パスナビのお問い合わせ
                </div>
                <div class="manual-item-description">
                  大学試験日程／学部情報／オープンキャンパス情報／資料請求についてのご質問はこちら
                </div>
              </div>
            </button>
            <button class="manual-item" matRipple (click)="gotoRequest()">
              <div>
                <div class="manual-item-title">
                  <mat-icon class="icon" aria-hidden="true">mail</mat-icon>
                  ご意見・ご要望について
                </div>
                <div class="manual-item-description">
                  サービスの改善や向上のために、皆様のご意見・ご要望を募集中です
                </div>
              </div>
            </button>
          </div>
        </app-content-row>
      </app-wrapper>
    </app-card-content>
  </app-content-row>

  <app-content-row>
    <app-card-content [showSeparator]="true">
      <app-card-content-head>
        <mat-icon class="card-content-head-icon">help_outline</mat-icon>
        よくあるご質問
      </app-card-content-head>
      <app-wrapper size="fluid">
        <div [id]="faq.id" *ngFor="let faq of faqs">
          <app-content-row>
            <app-faq-list-item [isExpanded]="faq.isExpanded" [questionTitle]="faq.title">
              <div class="mt-0" [innerHTML]="faq.body"></div>
            </app-faq-list-item>
          </app-content-row>
        </div>
      </app-wrapper>
    </app-card-content>
  </app-content-row>
</app-wrapper>
