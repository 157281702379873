import { createReducer, on } from '@ngrx/store';
import { AppError } from 'src/app/errors/app-error';
import { CommonIdGetTopicResponse } from 'src/app/models/common-id/common-id-topic';

import * as CommonIdTopicActions from '../../actions/common-id/common-id-topic.actions';

export const commonIdTopicFeatureKey = 'commonIdTopic';

export interface CommonIdGetTopicResult {
  success: boolean;
  response: CommonIdGetTopicResponse | null;
  error: AppError | null;
}

export interface State {
  commonIdGetTopicResult: CommonIdGetTopicResult | null;
}

export const initialState: State = {
  commonIdGetTopicResult: null
};

export const reducer = createReducer(
  initialState,
  on(CommonIdTopicActions.commonIdGetTopicSuccess, (state, { response }) => {
    const next: State = {
      ...state,
      commonIdGetTopicResult: { success: true, response, error: null }
    };
    return next;
  }),
  on(CommonIdTopicActions.commonIdGetTopicFailure, (state, { error }) => {
    const next: State = {
      ...state,
      commonIdGetTopicResult: { success: false, response: null, error }
    };
    return next;
  }),
  on(CommonIdTopicActions.initializeCommonIdGetTopicState, state => {
    const next: State = { ...state, commonIdGetTopicResult: null };
    return next;
  })
);
