<div *ngIf="isBannerVisible">
  <div class="bottom-toast-banner-spacer"></div>
  <div class="bottom-toast-banner" [ngClass]="{ 'search-route': isSearchRoute, 'animate-slide-in': isBannerVisible }">
    <div class="block h-[60px] overflow-clip w-[100vw] sm:w-[400px] sm:rounded-lg sm:shadow-2xl relative">
      <a
        class="bg-white bg-gradient-to-r from-yellow-gold/30 to-yellow-gold/5 flex items-center justify-center px-4 gap-4 hover:text-primary h-full"
        [href]="requestFormUrl"
        target="_blank"
      >
        <div class="mr-6 xxs:mr-0">
          <div class="text-md font-bold flex gap-1 items-center">
            <mat-icon class="mt-[1px] size-4 text-[1rem] text-primary" aria-hidden="true">mail</mat-icon>
            <p class="mb-0 leading-6">ご意見をお寄せください</p>
            <mat-icon class="mt-[1px] size-4 text-[1rem] text-primary" aria-hidden="true">open_in_new</mat-icon>
          </div>
          <div class="leading-3 text-xs">今後のサービス改善に役立ててまいります</div>
        </div>
        <img class="hidden xxs:block xs:mr-6" src="/assets/images/bnr-feedback-request-illust-s.png" width="110" height="60" alt="" />
      </a>
      <button
        (click)="closeBanner()"
        class="absolute top-1/2 -mt-3 right-3 size-6 rounded-full bg-black flex items-center justify-center hover:opacity-70 hover:scale-105"
        aria-label="Close Banner"
      >
        <mat-icon class="size-4 text-[1rem] text-white" aria-hidden="true">close</mat-icon>
      </button>
    </div>
  </div>
</div>
