import { Injectable } from '@angular/core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { Observable } from 'rxjs';
import { CommonIdGetTopicRequest, CommonIdGetTopicResponse } from 'src/app/models/common-id/common-id-topic';
import { CallableFunction } from 'src/app/resources/app-firebase';

@Injectable({
  providedIn: 'root'
})
export class CommonIdTopicService {
  constructor(private aff: AngularFireFunctions) {}

  commonIdGetTopic(request: CommonIdGetTopicRequest): Observable<CommonIdGetTopicResponse> {
    const callable = this.aff.httpsCallable(CallableFunction.COMMON_ID_GET_TOPIC);
    return callable(request);
  }
}
