import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import { FirebaseCallableFunctionError } from 'src/app/errors/firebase-callable-function-error';
import { CommonIdTopicService } from 'src/app/services/common-id/common-id-topic.service';

import * as CommonIdTopicActions from '../../actions/common-id/common-id-topic.actions';

@Injectable()
export class CommonIdTopicEffects {
  private LOG_SOURCE = this.constructor.name;

  commonIdGetTopic$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CommonIdTopicActions.commonIdGetTopic),
      switchMap(action =>
        this.commonIdTopicService.commonIdGetTopic(action.request).pipe(
          map(response => CommonIdTopicActions.commonIdGetTopicSuccess({ response })),
          catchError(e => of(CommonIdTopicActions.commonIdGetTopicFailure({ error: FirebaseCallableFunctionError.from(e) })))
        )
      )
    )
  );

  constructor(private actions$: Actions, private commonIdTopicService: CommonIdTopicService) {}
}
