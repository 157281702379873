<app-main-layout-spacer>
  <app-common-id-sign-out-header></app-common-id-sign-out-header>

  <app-wrapper size="lg" @enter *ngIf="!isTopicLoading">
    <app-content-row>
      <app-card-content [showSeparator]="false">
        <app-wrapper size="md">
          <app-content-row>
            <article class="topic-article">
              <app-content-row>
                <header class="topic-article-head">
                  <div class="topic-article-head-meta flex">
                    <time datetime="2021-08-13" class="text-base">{{ topic.publishedAt }}</time>
                  </div>
                  <h1 class="topic-article-head-title">{{ topic.title }}</h1>
                </header>
              </app-content-row>
              <app-content-row>
                <div class="topic-article-body">
                  <app-document-theme-default>
                    <div [innerHTML]="sanitize(topic.body)"></div>
                  </app-document-theme-default>
                </div>
              </app-content-row>
              <!-- リンクボタンはコメントアウトしておく
              <app-content-row>
                <div class="topic-article-link" *ngIf="topic.linkLabel !== null && topic.linkUrl !== null">
                  <button mat-flat-button class="mat-primary topic-link-button" (click)="gotoContact(topic.linkUrl)">
                    {{ topic.linkLabel }}
                  </button>
                </div>
              </app-content-row>
              -->
            </article>
          </app-content-row>
        </app-wrapper>
      </app-card-content>
    </app-content-row>
  </app-wrapper>

  <div *ngIf="isTopicLoading">
    <div class="progress-48">
      <mat-spinner [diameter]="48"></mat-spinner>
    </div>
  </div>

  <app-common-id-footer></app-common-id-footer>
</app-main-layout-spacer>
