import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, filter, take } from 'rxjs';

import { RootState } from 'src/app/reducers';
import { getCommonIdSignedInUser } from 'src/app/selectors/common-id/common-id-auth.selectors';

import { CommonIdUser } from 'src/app/models/common-id/common-id-user';
import { REQUEST_GOOGLE_FORM_URL } from 'src/app/resources/common-id-config';
import { CommonIdContactUtil } from 'src/app/utils/common-id/common-id-contact-util';

@Component({
  selector: 'app-common-id-bottom-toast-banner',
  templateUrl: './bottom-toast-banner.component.html',
  styleUrls: ['./bottom-toast-banner.component.scss']
})
export class CommonIdBottomToastBannerComponent implements OnInit {
  isBannerVisible = true;
  requestFormUrl: string;
  signedInUser$: Observable<CommonIdUser>;

  private readonly storageKey = 'bottomToastBannerClosedAt';
  private readonly hideDuration = 14 * 24 * 60 * 60 * 1000; // 2週間をミリ秒で

  constructor(private store: Store<RootState>) {}

  ngOnInit() {
    this.setUpSignedInUser();
    this.setUpRequestFormUrl();
    this.checkBannerVisibility();
  }

  private setUpSignedInUser() {
    this.signedInUser$ = this.store.select(getCommonIdSignedInUser).pipe(
      filter<CommonIdUser>(it => it != null),
      take(1)
    );
  }

  private setUpRequestFormUrl() {
    this.signedInUser$.subscribe(user => {
      this.requestFormUrl = CommonIdContactUtil.getRequestGoogleFormUrl(REQUEST_GOOGLE_FORM_URL, user.commonId);
    });
  }

  private checkBannerVisibility() {
    // バナー表示を解除後２週間はバナーを表示しない
    const closedAt = localStorage.getItem(this.storageKey);
    if (closedAt) {
      const closedTime = parseInt(closedAt, 10);
      const currentTime = Date.now();
      if (currentTime - closedTime < this.hideDuration) {
        this.isBannerVisible = false;
      }
    }
  }

  closeBanner() {
    this.isBannerVisible = false;
    const currentTime = Date.now();
    localStorage.setItem(this.storageKey, currentTime.toString());
  }
}
